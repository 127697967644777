import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import Selectors from '../Selectors';
import * as L from '../Utils/Lang';
import ItemInCart from '../Components/ItemInCart';
import { ProductFeatureExpandItem, ProductFeatureGraphicItem } from '../Components/ProductFeatureItem';

const ExpandItemData = [
  { title: "APN設定說明", imgFirst: "photo04.jpg", imgLast: "photo05.jpg", single: false },
  { title: "網速", imgFirst: "photo06.jpg", imgLast: "", single: true }
];

const GraphicItemData = [
  { title: "當地電信商", img: "photo07.jpg" },
  { title: "公平流量", img: "photo08.jpg" },
];

class ProductDetailPage extends React.Component {
  render() {
    let { item } = this.props;

    return (
      <Wrapper>
        <Container>
          <ProductItemRow img={item.image} css="margin-bottom: 68px;">
            <div className="left">
              <div className="title">{L.d(item, 'display_name')}</div>
              <div className="description">{L.d(item, 'description')}</div>
              <div className="graphic" />
            </div>

            <div className="right">
              <ItemInCart itemId={item.id} display="product" />
            </div>
          </ProductItemRow>

          {
            ExpandItemData.map((d, i) =>
              <ProductFeatureExpandItem key={"expand-item" + i} item={d} />
            )
          }

          {
            GraphicItemData.map((d, i) =>
              <ProductFeatureGraphicItem key={"graphic-item" + i} item={d} />
            )
          }
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 250px 25px 200px;

  @media screen and (max-width: 600px) {
    padding: 205px 25px 100px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 876px;
  width: 100%;
`;

const ProductItemRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > .left {
    margin-right: 20px;
    max-width: 360px;
    width: 100%;

    & > .title {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 30px;
      color: #575757;
      overflow: hidden;
    }

    & > .description {
      margin-bottom: 5px;
      font-size: 14px;
      color: #343434;
    }

    & > .graphic {
      background-image: url(${props => props.img});
      background-color: lightgray;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      width: 100%;
      height: 326px;
    }
  }

  & > .right {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #E1E1E1;
    padding: 20px;
    max-width: 417px;
    width: 100%;
  }

  @media screen and (max-width: 730px) {
    & > .left {
      max-width: 240px;
      & > .graphic {
        height: 199px;
      }
    }

    & > .right {
      max-width: 280px;
    }
  }

  @media screen and (max-width: 549px) {
    flex-wrap: wrap;

    & > .left {
      max-width: 100%;
      margin-right: 0px;
      margin-bottom: 20px;
    }

    & > .right {
      max-width: 100%;
    }
  }

  ${props => props.css};
`;


export default withPage(
  connect(
    (state, ownProps) => ({
      item: Selectors.getProduct(state, ownProps.pageContext.itemId),
    }),
    null
  )(ProductDetailPage)
);
