import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";

export class ProductFeatureExpandItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showExpand: false
        }
    }

    render() {
        let { item } = this.props;
        let { showExpand } = this.state;
 
        return (
            <Wrapper onClick={() => this.setState({ showExpand: !showExpand })}>
                <div className="title">
                    {
                        showExpand
                        ?   <Icon.KeyboardArrowDown
                                color="#575757"
                                style={{ width: 28, height: 28, marginRight: 5 }}
                            />
                        :   <Icon.KeyboardArrowRight
                                color="#575757"
                                style={{ width: 28, height: 28, marginRight: 5 }}
                            />
                    }
                    <span>{item.title}</span>
                </div>

                <div className="detail">
                    {
                        showExpand && item.single
                        && <Graphic img={`/images/redesign/${item.imgFirst}`} css="margin-bottom: 20px;" />
                    }
                    {
                        showExpand && !item.single
                        && <div className="graphics">
                            <Graphic img={`/images/redesign/${item.imgFirst}`} css="margin-bottom: 20px;" />
                            <Graphic img={`/images/redesign/${item.imgLast}`} css="margin-bottom: 20px;" />
                          </div>

                    }
                </div>
            </Wrapper>
        )
    }
}

export class ProductFeatureGraphicItem extends Component {
    render() {
        let { item } = this.props;

        return (
            <Wrapper>
                <div className="title">
                    <span>{item.title}</span>
                </div>

                <div className="detail">
                    <Graphic img={`/images/redesign/${item.img}`} css="margin-bottom: 20px;" />
                </div>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    border-top: 1px solid #979797;
    padding: 20px 0px 0px;
    width: 100%;

    & > .title {
        display: flex;
        align-items: center;

        margin-bottom: 20px;
        font-weight: 400;
        font-size: 20px;
        color: #575757;
        cursor: pointer;
    }

    & > .detail {
    }
`;

const Graphic = styled.div`
    background-color: #FFFFFF;
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    min-height: 492px;

    @media screen and (max-width: 768px) {
        min-height: 400px;
    }

    @media screen and (max-width: 600px) {
        min-height: 300px;
    }

    @media screen and (max-width: 414px) {
        min-height: 200px;
    }

    @media screen and (max-width: 375px) {
        min-height: 180px;
    }

    ${props => props.css}
`;